import React, { Component } from 'react';

class Portfolio3 extends Component {
  render() {

    if(this.props.data){
      var projects3 = this.props.data.projects3.map(function(projects3){
        var projectImage = 'images/portfolio/'+projects3.image;
        return <div key={projects3.title} className="columns portfolio-item">
           <div className="item-wrap">
            <a href={projects3.url} title={projects3.title}>
               <img alt={projects3.title} src={projectImage} />
               <div className="overlay">
                  <div className="portfolio-item-meta">
                 <h5>{projects3.title}</h5>
                     <p>{projects3.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
            <div alt={projects3.title} className="portfolio-item-title">
              {projects3.title}
            </div>
          </div>
        </div>
      })
    }

    return (
      <section id="portfolio3">

      <div className="row">

         <div className="twelve columns collapsed">

            <h1>Some Github iOS Projects - Check more on Github</h1>

            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {projects3}
            </div>
          </div>
      </div>
   </section>
    );
  }
}

export default Portfolio3;
